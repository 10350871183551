/*noinspection CssUnknownTarget*/
@import "assets/css/typography.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --viewport: 210 40% 96.1%;
        --viewport-foreground: 222.2 47.4% 11.2%;

        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71% 4%;
        --foreground: 213 31% 91%;

        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;

        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        --border: 216 34% 17%;
        --input: 216 34% 17%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;

        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;

        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;

        --destructive: 0 63% 31%;
        --destructive-foreground: 210 40% 98%;

        --ring: 216 34% 17%;

        --radius: 0.5rem;
    }
}

@layer base {
    body {
        @apply bg-viewport text-foreground;
        font-feature-settings: "rlig" 1, "calt" 1;
    }
}

.amplify-button--primary {
    @apply bg-primary text-primary-foreground;
}


/**Custom Markdown Text Styling*/

.customMarkdown h1 {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 16px;
}

.customMarkdown h2 {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 16px;
}

.customMarkdown h3 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 16px;
}

.customMarkdown p {
    font-size: 16px;
    margin-bottom: 16px;
}

.customMarkdown ul {
    list-style-type: disc;
    padding-left: 40px;
}

.customMarkdown li {
    font-size: 16px;
    margin-bottom: 8px;     /* Add space between list items */
}

.customMarkdown ol {
    list-style-type: disc;
    padding-left: 40px;
}


.customMarkdown table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    text-align: left;
}

.customMarkdown th,
.customMarkdown td {
    padding: 15px;
    border-bottom: 1px solid #ddd;
}

.customMarkdown th {
    background-color: #f2f2f2;
}

.customMarkdown tr:hover {
    background-color: #f5f5f5;
}

/* styles.css */
.gradient-text {
    position: relative;
    background: linear-gradient(to right, #2C55FB, #9B35D6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-text::before {
    content: '';
    background: white /* Your background color here */;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 8px; /* Your desired border radius */;
}

.custom-sticky {
    position: sticky;
    top: 0;
}




