@font-face {
    font-family: 'Gilroy Extrabold';
    src: url('../fonts/gilroy-extrabold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy Bold';
    src: url('../fonts/gilroy-bold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy Semi-bold';
    src: url('../fonts/gilroy-semibold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy Medium';
    src: url('../fonts/gilroy-medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy Regular';
    src: url('../fonts/gilroy-regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
